








import { Component, Prop, PropSync, Vue, Ref, Model } from "vue-property-decorator";

@Component({
})
export default class Address extends Vue {

  // @Prop() action!: "CreateItem" | "ModifyItem" | "RequestLabel";
  // @Prop() stateName!: string; 
  // @Prop() identity!: "Sender" | "Receiver" | "";
  // @Prop() templateId!: string | "0";

  // vuex path to commit updated address
  @Prop() addressMutation!: string;

  // vuex path to get address filling 
  @Prop() savedAddressGetter!: string;
  @Prop() addressId!: string;
  @Prop() title!: string;
  @Prop() completingRedirectName!: string;

  public address: Address | ExpressOrderAddress | null = null;
  public buildingAddress: BuildingAddress | null = null;

  beforeCreate() {
    this.$store.commit("hideTopBar");
    this.$store.commit("hideNavBar");
    this.$store.commit("hideTitle");
  }


}
